import axios from "axios";

let baseURL = "";

if (process.env.NODE_ENV === "prod") {
    // Set the production base URL
    baseURL = "https://api.go2learnai.com/api";
} else if (process.env.NODE_ENV === "dev") {
    // Set the development base URL
    console.log("eneeeeeee")
    baseURL = "https://api.dev.npn.go2learnai.com/api";
} else if (process.env.NODE_ENV === "stg") {
    // Set the development base URL
    baseURL = "https://api.stg.npn.go2learnai.com/api";
}
else {
    baseURL = "http://localhost:4000/api";
}

const instance = axios.create({ baseURL });

export const baseurl = baseURL;

export default instance;
