import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import axios from "../baseurl"
import { useNavigate, useParams } from "react-router-dom";
const StoryLineContainer = (initialState={}) => {
    const UserId = localStorage.getItem("UserId");
    const session_id = localStorage.getItem("classRoomSessionId");

    const [allStories, setAllStories] = useState([])
    const [currentStroryIndex, setCurrentStoryIndex] = useState(-2)
    const [isLoading, setIsLoading] = useState(false)
    const [currentChat, setCurrentChat] = useState({})
    const [tabIndex, setTabIndex] = useState(0)
    const { template_id } = useParams();
    const [loader, setloader] = useState(false);
    const [Template, setTemplate] = useState(null);
    
    console.log("In StoryLineContainer", template_id)
    const navigate = useNavigate();
    const GetTemplates = async () => {
        const response = await axios.get("/master/template/" + template_id);
        if (response.data.success) {
            setTemplate(response.data.template);
            setloader(false);
        } else {
            setloader(false);
            navigate("/landingpage");
        }
    };

    useEffect(() => {
        if (!template_id) {
            navigate("/landingpage");
        }
        setloader(true);
        GetTemplates();
    }, []);
    useEffect(() => {
        // fetch all stories
        setIsLoading(true)
        console.log("In Controller : fetching all stories")
        axios.get("/student/story_board?user_id=" + UserId +'&template_id='+template_id).then((response) => {
            console.log(response.data);
            if (response.data.success) {
                setAllStories(response.data.data);
            }
            setIsLoading(false)
        });
    }, [])

    useEffect(() => {
        if (currentStroryIndex >= 0 && allStories[currentStroryIndex]) {
            const chat_id = allStories[currentStroryIndex].chat_id
            axios
                .get(
                    "/student/chat/getbyid?chat_id=" +
                    chat_id
                )
                .then((response) => {
                    if (response.data.success) {
                        setCurrentChat(response.data.data);
                    }
                });
        }
    }, [currentStroryIndex, tabIndex])

    //Auto Save Story
    const saveStory = (index,name,boards) => {
        console.log(index, currentStroryIndex)
        console.log("Auto Saving Story", name, boards)
        if (index >= 0 && allStories[index]) {
            const story = allStories[index]
            const story_id = story.id
            axios.post("/student/story_board/update", {
                story_id,
                data: {
                    name,
                    story_boards: boards
                }
            }).then((response) => {
                if (response.data.success) {
                    allStories.map((story, index) => {
                        if (story.id === story_id) {
                            allStories[index] = response.data.data
                            setAllStories([...allStories])
                        }
                    })
                    console.log("Story Auto Saved", index)
                }
            });
        }
    }


    const createNewStory = (boardName) => {
        setIsLoading(true)
        axios.post("/student/story_board", {
            user_id: UserId,
            data: {
                name: boardName,
                story_boards: [],
            },
            session_id: session_id,
            template_id: template_id,
            assistantId: Template.assistantId,
        }).then((response) => {
            setIsLoading(false)
            if (response.data.success) {
                setAllStories([response.data.data,...allStories])
                setCurrentStoryIndex(0)
            }
        });
    }

    const updateStory = (story_id, story) => {
        console.log("Updating Story", story_id)
        setIsLoading(true)
        axios.post("/student/story_board/update", {
            story_id,
            data: story
        }).then((response) => {
            setIsLoading(false)
            if (response.data.success) {
                allStories.map((story, index) => {
                    if (story._id === story_id) {
                        allStories[index] = response.data.data
                        setAllStories([...allStories])
                    }
                })
            }
        });
    }

    return {
        allStories,
        isLoading,
        currentStroryIndex,
        currentChat,
        tabIndex,
        setCurrentStoryIndex,
        createNewStory,
        updateStory,
        setAllStories,
        saveStory,
        setTabIndex,
        Template,
    }
}

export default createContainer(StoryLineContainer)