import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { CircularProgress, Box } from '@mui/material';
import StoryLineContainer from "./container/StoryLineContainer.js";

// Lazy load components
const LandingPage = lazy(() => import("./playground/landingPage.jsx"));
const LoginComponent = lazy(() => import("./playground/login.jsx"));
const AdminLogin = lazy(() => import("./admin/AdminLoginpage.jsx"));
const StudentLogin = lazy(() => import("./playground/studentLogin.jsx"));
const ParentLoginComponent = lazy(() => import("./parent/ParentLogin.jsx"));
const TeacherLoginComponenet = lazy(() => import("./playground/teacherLogin.jsx"));
const AdminDashboard = lazy(() => import("./admin/pages/dashboard/Dashboard.jsx"));
const ParentPortel = lazy(() => import("./parent/Parent.jsx"));
const ClassRoomManager = lazy(() => import("./classroomManager/index.jsx"));
const NewLandingPage = lazy(() => import("./playground/NewLandingPage.jsx"));
const TilesStartPage = lazy(() => import("./playground/TilesStartPage.jsx"));
const Editor = lazy(() => import("./Editor.js"));
const ComicStrip = lazy(() => import("./playground/ComicStrip.jsx"));
// const StoryLineContainer = lazy(() => import("./container/StoryLineContainer.js"));
const StoryLine = lazy(() => import("./playground/StoryLine.jsx"));
const WarTime = lazy(() => import("./playground/WarTime.jsx"));
const PublishComic = lazy(() => import("./playground/PublishComic.jsx"));

function useChangeDocumentTitle() {
    const location = useLocation();
    useEffect(() => {
        // Set document title based on the current pathname
        if (location.pathname === "/home" || location.pathname === "/") {
            document.title = "Go2Learn Ai";
        } else if (location.pathname === "/login") {
            document.title = "Go2Learn Ai - Login";
        } else if (location.pathname === "/admin") {
            document.title = "Go2Learn Ai: Admin Login";
        } else if (location.pathname === "/parentLogin") {
            document.title = "Go2Learn Ai: Parent Login";
        } else if (location.pathname === "/student") {
            document.title = "Go2Learn Ai: Student Login";
        } else if (location.pathname === "/teacher") {
            document.title = "Go2Learn Ai: Teacher Login";
        } else if (location.pathname === "/admin_dashboard") {
            document.title = "Go2Learn Ai: Admin Dashboard";
        } else if (location.pathname === "/parentPortal") {
            document.title = "Go2Learn Ai: Parent Portal";
        } else if (location.pathname === "/classroomManager") {
            document.title = "Go2Learn Ai: Classroom Manager";
        } else if (location.pathname === "/landingpage") {
            document.title = "Go2Learn Ai : Courses";
        } else if (location.pathname === "/tiles") {
            document.title = "Go2Learn Ai : Tiles";
        } else if (location.pathname === "/ComicStrip") {
            document.title = "Go2Learn Ai : Comic Strip";
        } else if (location.pathname === "/StoryLine") {
            document.title = "Go2Learn Ai : Story Line";
        } else if (location.pathname === "/wartime") {
            document.title = "Go2Learn Ai : War Time";
        }
        // Add more conditions for other paths as needed
        // else if (location.pathname === "/another-route") {
        //     document.title = "Another Route - Your Site Name";
        // }
        window.scrollTo(0, 0);
    }, [location]);
}

function Loading() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}
        >
            <CircularProgress />
        </Box>
    );
}

export default function App() {
    useChangeDocumentTitle();

    return (
        <Suspense fallback={<Loading />}>
            <Routes>
               
                {/* <Route path="/gui" exact element={<gui />} /> */}
                <Route path="/home" exact element={<LandingPage />} />
                <Route path="/" exact element={<LandingPage />} />
                <Route path="/login" exact element={<LoginComponent />} />
                <Route path="/admin" element={<AdminLogin />} />
                <Route path="/parentLogin" element={<ParentLoginComponent />} />
                <Route path="/student" element={<StudentLogin />} />
                <Route path="/teacher" element={<TeacherLoginComponenet />} />
                <Route path="/admin_dashboard" element={<AdminDashboard />} />
                <Route path="/parentPortal" element={<ParentPortel />} />
                <Route path="/classroomManager" element={<ClassRoomManager />} />
                <Route path="/landingpage" element={<NewLandingPage />} />
                <Route path="/tiles" element={<TilesStartPage />} />
                <Route path="/editor/*" element={<Editor />} />
                <Route path="/ComicStrip/:template_id" element={<ComicStrip />} />
                <Route path="/StoryLine/:template_id" element={<StoryLineContainer.Provider><StoryLine /></StoryLineContainer.Provider>} />
        
                <Route path="/bot/:name/:template_id" element={<WarTime />} />
                <Route path="/PublishedComic" element={<PublishComic />} />
            </Routes>
        </Suspense>
    );
}